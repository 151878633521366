html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  flex-direction: column;
}

#content-div {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
